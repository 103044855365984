<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div v-else>
      <HeaderPanel
        title="RQP"
        :filter="filter"
        :hasFilter="false"
        :hasDropdown="false"
        @search="handleSearch"
        placeholder="ค้าหาเลขที่เอกสาร"
      />

      <div class="bg-white border-red p-2 p-lg-3 mt-3">
        <b-tabs content-class="" v-model="tabIndex">
          <b-tab
            v-for="status of statusList"
            :key="status.id"
            :value="status.id"
            @click="onTabChange(status.id, $event)"
          >
            <template #title>
              {{ status.name }}

              ({{ status.count }})
            </template>
            <b-table
              responsive
              striped
              hover
              :fields="fields"
              :items="items"
              :busy="isBusy"
              show-empty
              empty-text="No matching records found"
            >
              <template v-slot:cell(created_time)="data">
                <div>{{ data.item.created_time | moment($formatDateNew) }}</div>
                <div class="time-color">
                  {{ data.item.created_time | moment("HH:mm:ss") }}
                </div>
              </template>
              <template v-slot:cell(request_branch_name)="{ item }">
                <div>{{ item.request_branch_code }}</div>
                <div class="time-color">
                  {{ item.request_branch_name }}
                </div>
              </template>
              <template v-slot:cell(note)="{ item }">
                {{ item.note || "-" }}
              </template>
              <template v-slot:table-busy>
                <div class="text-center text-black my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong class="ml-2">Loading...</strong>
                </div>
              </template>
            </b-table>
            <b-row>
              <b-col cols="12" md="6" class="form-inline justify-content-start">
                <p class="mb-0 p-gray">
                  Showing
                  <span v-if="items.length === 0" id="from-row">0</span>
                  <span v-else id="from-row">{{
                    (filter.page - 1) * filter.take + 1
                  }}</span>
                  to
                  <span id="to-row">
                    {{
                      filter.take * filter.page > rows
                        ? rows
                        : filter.take * filter.page
                    }}</span
                  >
                  of <span id="total-row">{{ rows }}</span> entries
                </p>
              </b-col>
              <b-col cols="12" md="6" class="form-inline justify-content-end">
                <b-pagination
                  v-model="filter.page"
                  :total-rows="rows"
                  :per-page="filter.take"
                  first-text="First"
                  prev-text="Prev"
                  next-text="Next"
                  last-text="Last"
                  class="m-md-0"
                  @change="pagination"
                ></b-pagination>
                <b-form-select
                  class="ml-2"
                  v-model="filter.take"
                  @change="handleChangeTake"
                  :options="pageOptions"
                ></b-form-select>
              </b-col>
            </b-row>
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import OtherLoading from "@/components/loading/OtherLoading";
export default {
  components: {
    OtherLoading,
  },
  data() {
    return {
      rows: 0,
      items: [],
      fields: [
        {
          label: "วันที่",
          key: "created_time",
        },
        {
          label: "เลขที่เอกสาร",
          key: "rqp_no",
        },
        {
          label: "จำนวนรายการ",
          key: "product_count",
        },
        {
          label: "ปลายทาง",
          key: "request_branch_name",
        },
        {
          label: "การอนุมัติ",
          key: "is_completed",
        },
        {
          label: "สถานะ",
          key: "status_display",
        },
        {
          label: "หมายเหตุ",
          key: "note",
        },
        {
          label: "Action",
          key: "action",
        },
      ],
      isBusy: true,
      isLoading: true,
      pageOptions: [
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      showing: 1,
      showingTo: 10,
      filter: {
        search: "",
        take: 10,
        page: 1,
        rqp_status: [0],
      },
      tabIndex: 0,
      statusList: [],
    };
  },
  async created() {
    await this.getStatus();
    await this.getList();
  },
  methods: {
    async getList() {
      this.isBusy = true;
      let payload = { ...this.filter };

      const res = await this.axios.post(`/rqp/list`, payload);

      if (res.data.result == 1) {
        this.items = res.data.detail.rqp_list_response;

        this.rows = res.data.detail.total_count;
      }

      this.isLoading = false;
      this.isBusy = false;
    },
    async getStatus() {
      this.isBusy = true;
      let payload = { ...this.filter };

      const res = await this.axios.post(`/rqp/status`, payload);
      if (res.data.result == 1) {
        this.statusList = res.data.detail;
      }

      this.isBusy = false;
    },
    onTabChange(val, e) {
      this.filter.rqp_status = [val];
      this.getList();
    },
    handleSearch() {
      this.filter.page = 1;
      this.showing = 1;
      this.showingTo = this.filter.take;
      this.getList();
    },
    handleChangeTake(take) {
      this.filter.page = 1;
      this.filter.take = take;
      this.showingTo = this.filter.take;
      this.getList();
    },
    pagination(value) {
      this.filter.page = value;
      if (value === 1) {
        this.showing = this.filter.page;
        this.showingTo = this.filter.take;
      } else {
        this.showing = value * this.filter.take - this.filter.take;
        this.showingTo = this.showing + this.filter.take;
      }
      this.getList();
    },
  },
};
</script>

<style lang="scss" scoped></style>
