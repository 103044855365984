<template>
  <div>
    <div class="f-20 mb-3 d-flex justify-content-between">
      <b> EMAIL TEMPLATE </b>
      <b-button
        class="btn-preview"
        @click="openEmailPreview()"
        v-if="![31, 32, 33].includes(Number(id))"
      >
        <font-awesome-icon icon="mobile-alt" class="d-md-none" />
        <span class="d-none d-md-block">
          <font-awesome-icon icon="mobile-alt" class="pointer" />
          <span class="btn-text">Preview</span>
        </span>
      </b-button>
    </div>
    <div class="title-tabs">
      {{ topic }}
      <span>
        {{ id == 33 ? "(Notify Customer)" : id == 31 ? "(Notify Agent)" : "" }}
      </span>
    </div>

    <b-container class="no-gutters bg-white">
      <b-card class="border-none">
        <b-card-text>
          <b-skeleton-wrapper :loading="isLoading">
            <InputText
              textFloat="Subject"
              placeholder="Subject"
              type="text"
              v-model="subject"
            />

            <template v-if="[31, 32, 33].includes(Number(id))">
              <TextEditorsTiny
                textFloat="Description"
                :rows="4"
                :value="MCEvalue"
                :name="'description_' + 1"
                placeholder="Type something..."
                @onDataChange="(val) => (MCEvalue = val)"
                isRequired
                :variables="MCEVariable"
                :v="$v.MCEvalue"
                :isValidate="$v.MCEvalue.$error"
              />
            </template>
            <template v-else>
              <EmailEditor
                ref="emailEditor"
                pdpaEmail
                :variableItem="
                  MCEVariable.map((el) => {
                    return { label: el, value: '{{' + el + '}}' };
                  })
                "
                @onDataChange="(val) => setValue(val)"
                v-model="MCEvalue"
                :template="emailTemplate"
                @clearField="clearField"
            /></template>
            <div class="input-action admin" v-if="id == 31">
              <div>Notify Agent : {{ displayValue || "Add Agents" }}</div>
              <b-button
                @click="handleAssignTo()"
                variant="custom-primary"
                size="sm"
                class="mt-2"
              >
                Add Agent
              </b-button>
            </div>
          </b-skeleton-wrapper>
          <div class="mt-3" v-if="id == 33">
            <b-form-checkbox
              switch
              v-model="active"
              class="radio-active"
              size="lg"
              :value="1"
              :unchecked-value="0"
            >
              <span class="ml-2 main-label">{{
                active ? "Active" : "Inactive"
              }}</span>
            </b-form-checkbox>
          </div>
        </b-card-text>
      </b-card>
    </b-container>
    <FooterAction routePath="/setting/email-template" @submit="exportHtml()" />
    <ModalLoading ref="modalLoading" />
    <ModalAdminList
      :ref="`adminModal`"
      :selectedAdmin="agent_list.map((el) => el.user_guid)"
      @select="handleSelectedAdmin"
      isList
    />
  </div>
</template>
<script>
import ModalAdminList from "@/components/ticket/activity/ModalAdminList";
import ModalLoading from "@/components/modal/ModalLoading";
import EmailEditor from "@/components/inputs/EmailEditor";
import OtherLoading from "@/components/loading/OtherLoading";
import { required } from "vuelidate/lib/validators";
import requiredIf from "vuelidate/lib/validators/requiredIf";
export default {
  components: { ModalLoading, EmailEditor, OtherLoading, ModalAdminList },
  data() {
    return {
      tabIndex: 1,
      tabs: [],
      MCEvalue: "",
      design: "",
      freezeMCEValue: "",
      MCEVariable: [],
      id: this.$route.params.id,
      screenSize: window.innerWidth,
      newTabIndex: 1,
      prevTabIndex: 1,
      isLoading: false,
      emailTemplate: { html: "", css: "" },
      newId: 0,
      topic: "",
      subject: "",
      selectedAdmin: null,
      displayValue: "",
      label_admin: "",
      agent_list: [],
      active: 0,
    };
  },
  validations: {
    MCEvalue: {
      required: requiredIf(function (item, va) {
        return true;
      }),
    },
  },
  mounted() {},
  watch: {
    screenSize(val) {
      return val;
    },
  },
  computed: {
    isMobile() {
      return this.screenSize < 1024 ? false : true;
    },
  },
  created() {
    this.getPDPAbyEmail();
    window.addEventListener("resize", this.onResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    openEmailPreview() {
      document.documentElement.style.overflow = "hidden";
      this.$refs.emailEditor.openPreviewPopup();
    },
    setValue(val) {
      this.MCEvalue = val.compileHtml;
      this.emailTemplate = val.template;
    },
    async exportHtml() {
      if (![31, 32, 33].includes(Number(this.id))) {
        const { template } = await this.$refs.emailEditor.compileEmail();

        var data = {
          value: template.html,
          design: template.css,
        };
      }
      await this.saveForm(data);
    },
    onResize(e) {
      this.screenSize = window.innerWidth;
    },

    async getPDPAbyEmail(condition = true) {
      this.isLoading = true;

      this.id = this.$route.params.id;

      const response = await this.axios(`/setting/pdpaemail/${this.id}`);

      this.topic = response.data.detail.email_info.topic;
      this.active = response.data.detail.email_info.active;
      if ([31, 32, 33].includes(Number(this.id))) {
        this.subject = response.data.detail.email_info.subject;
        this.MCEvalue = response.data.detail.email_info.value;
        this.agent_list = response.data.detail.agent.map((el) => {
          return {
            name: `${el.name} ${el.email ? `(${el.email})` : ""}`,
            user_guid: el.user_guid,
          };
        });

        this.displayValue = response.data.detail.agent
          .map((el) => `${el.name} ${el.email ? `(${el.email})` : ""}`)
          .join(", ");

        this.MCEVariable = response.data.detail.variable;
      } else {
        if (!response.data.detail.email_info) {
          response.data.detail.email_info = {
            value: "",
            design: "",
          };
        }

        this.emailTemplate = {
          html: response.data.detail.email_info.value
            ? response.data.detail.email_info.value
            : "",
          css: response.data.detail.email_info.design
            ? response.data.detail.email_info.design
            : "",
        };

        this.freezeMCEValue = response.data.detail.email_info.value;
        this.subject = response.data.detail.email_info.subject;
        this.MCEVariable = response.data.detail.variable;
      }
      this.isLoading = false;
    },
    addVariable(val) {
      var ele = document.getElementsByClassName("mce-edit-focus");
      ele[0].getElementsByTagName("p")[0].innerText += `{{${val}}}`;
      // this.MCEvalue += `{{${val}}}`;
    },
    async saveForm(val) {
      if ([31, 32, 33].includes(Number(this.id))) {
        this.$v.$touch();
        if (this.$v.$error) return;
        var payload = {
          id: this.id,
          subject: this.subject,
          value: this.MCEvalue,
          agent: this.agent_list,
          active: this.active,
        };
      } else {
        var payload = {
          id: this.id,
          subject: this.subject,

          ...val,
        };
      }
      this.$bus.$emit("showLoading");
      return this.axios.post(`/setting/pdpaemail`, payload).then((res) => {
        if (res.data.result) {
          this.successAlert().then(() =>
            this.$router.push("/setting/email-template")
          );
        } else {
          this.errorAlert(res.data.message);
        }
        this.$bus.$emit("hideLoading");
        return res;
      });
    },
    clearField() {},
    handleAssignTo(index, value) {
      this.$refs[`adminModal`].show();
    },
    handleSelectedAdmin(admin, isAssignTome = false) {
      if (this.agent_list.find((el) => el.user_guid == admin.user_guid)) {
        let index = this.agent_list.findIndex(
          (el) => el.user_guid == admin.user_guid
        );
        this.agent_list.splice(index, 1);
      } else {
        this.agent_list.push({
          name: `${admin.name} ${admin.email ? `(${admin.email})` : ""}`,
          user_guid: admin.user_guid,
        });
      }

      this.displayValue = this.agent_list.map((el) => el.name).join(", ");
      this.$forceUpdate();
    },
  },
};
</script>
<style lang="scss" scoped>
.btn-preview button,
.btn-preview {
  color: var(--primary-color) !important;
  border-color: var(--primary-color);
  font-weight: 100;
  background-color: white;
}
.f-20 {
  font-size: 20px;
}
.nav-link {
  text-decoration: unset;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--font-color) !important;
  background-color: var(--theme-secondary-color);
}
.tabs-title-spacing {
  /* padding: 0px 15px 0 15px !important; */
  margin: 10px 0px;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}
.btn-fixed {
  min-width: 150px;
}
.my-actions {
  margin: 0 2em;
}
.order-1 {
  order: 1;
}
.order-2 {
  order: 2;
}
.order-3 {
  order: 3;
}

.right-gap {
  margin-right: auto;
}
iframe {
  height: 80vh !important;
  max-height: 100% !important;
  min-width: unset !important;
}
@media screen and (max-width: 1024px) {
  .tabs-title-spacing {
    border-right: unset;
  }
}

.bg-white ::v-deep .tap-content {
  background-color: #f7f7f7;
}
.input-action {
  position: relative;
  width: 100%;

  ::v-deep .input-custom input {
    cursor: pointer !important;
  }

  .input-action-icon {
    cursor: pointer;
    position: absolute;
    z-index: 1;
    top: 27px;
    right: 5px;
    width: 32px;
    height: 32px;
    background-color: inherit;
    color: var(--primary-color);
  }

  &.admin {
    .input-action-icon {
      top: 28px;
    }
  }
}
</style>
