<template>
  <div>
    <HeaderPanel
      title="Email Signature"
      :hasFilter="false"
      :hasDropdown="false"
      :hasSearch="false"
    >
    </HeaderPanel>

    <div class="bg-white border-red p-2 p-lg-3 mt-3">
      <TextEditorsTiny
        textFloat="Description"
        :rows="4"
        :value="form.description"
        :name="'description_' + 1"
        placeholder="Type something..."
        @onDataChange="(val) => (form.description = val)"
        isRequired
      />
    </div>
    <FooterAction routePath="/setting" @submit="submit()" />
  </div>
</template>

<script>
export default {
  components: {},

  data() {
    return {
      isLoading: false,
      form: "",
    };
  },

  async mounted() {
    await this.getList();
  },
  watch: {},
  destroyed() {},
  methods: {
    clearValue() {},
    async getList() {
      this.$bus.$emit("showLoading");
      const res = await this.axios(`setting/Get/Email/Signature`);

      this.$bus.$emit("hideLoading");
      if (res.data.result === 1) {
        this.form = res.data.detail;
      }
    },
    async submit() {
      this.$bus.$emit("showLoading");
      const res = await this.axios.put(
        `setting/Update/Email/Signature`,
        this.form
      );

      this.$bus.$emit("hideLoading");
      if (res.data.result === 1) {
        this.successAlert().then(() => this.$router.push("/setting"));
      } else {
        this.errorAlert();
      }
    },
  },
};
</script>

<style scoped>
.icon-size {
  font-size: 20px;
  color: #575757;
}
</style>
