<template>
  <div>
    <div
      v-if="!notShow"
      class="bg-white d-flex flex-column justify-content-center align-items-center"
      style="height: 500px"
    >
      <p><b>No Matching Records Found</b></p>
      <b-button variant="custom-outline-primary" @click="createUser()">
        Create New Customer
      </b-button>
    </div>
    <SidebarCreateNewUser
      ref="sidebarProfileEdit"
      :memberLevelList="memberLevelList"
      :FieldList="FieldList"
      @validateForm="validateForm"
      :isLoadingUpdateProfile="isLoadingUpdateProfile"
      :isConsentList="isConsentList"
      :limit="limit"
      @onFinish="onFinish"
      :ticket="ticket"
    />
  </div>
</template>

<script>
import SidebarCreateNewUser from "./SidebarCreateNewUser.vue";

export default {
  components: {
    SidebarCreateNewUser,
  },
  props: {
    notShow: {
      required: false,
      default: false,
      type: Boolean,
    },
    ticket: {
      required: false,
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      FieldList: [],
      memberLevelList: [],
      isLoadingUpdateProfile: false,
      isConsentList: [],
      limit: 0,
    };
  },
  mounted() {
    if (this.$route.params.id == 0) this.createUser();
  },
  methods: {
    async telephoneLimit() {
      const { data } = await this.axios(`/setting/GetSecondaryTelephone`);
      this.limit = data.detail;
    },
    async createUser(email) {
      console.log(email);
      this.telephoneLimit();
      this.$refs.sidebarProfileEdit.show(email);
    },
    async getFields(type = 1) {
      if (type == 2) {
        const { data } = await this.axios(`/setting/getcompanyform`);

        this.FieldList = data.detail.profileField;
      } else {
        if (this.FieldList.length == 0)
          await this.$store.dispatch("getFieldProfiletypes");

        let data = this.$store.state.form.profileLists;
        this.choice = {
          province: await data.detail.fields.find(
            (el) => el.field_profile_type_id == 18
          ),
          district: await data.detail.fields.find(
            (el) => el.field_profile_type_id == 17
          ),
          subdistrict: await data.detail.fields.find(
            (el) => el.field_profile_type_id == 16
          ),
          prefix: await data.detail.fields.find(
            (el) => el.field_profile_type_id == 2
          ),
          gender: await data.detail.fields.find(
            (el) => el.field_profile_type_id == 21
          ),
        };
        this.FieldList = data.detail.fields;
      }
      return this.choice;
    },
    async getMemberLevelList() {
      await this.$store.dispatch("getMemberLevel");
      let data = this.$store.state.automation.memberLevel;
      if (data.result == 1) {
        this.memberLevelList = data.detail;
      }
    },
    async validateForm(val) {
      this.form = val;
      this.$v.form.$touch();
      return this.$v.form.$error;
    },
    onFinish(user_guid) {
      this.$emit("onFinish", user_guid);
    },
  },
};
</script>

<style></style>
