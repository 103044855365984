<template>
  <div>
    <b-container class="no-gutters bg-white py-3">
      <b-row class="align-items-center">
        <template v-for="(item, index) in data">
          <b-col cols="3" :key="'select-' + item.id">
            <InputSelect
              title="Action"
              :options="options"
              v-model="item.ticket_webhook_action_id"
            />
          </b-col>
          <b-col cols="8" :key="item.id">
            <InputText
              placeholder=""
              customLabel
              type="text"
              v-model="item.url"
            >
              <template v-slot:textFloat>
                <div class="d-flex justify-content-between mb-1">
                  <template>
                    <div>{{ item.webhook_name }}</div>
                    <div
                      class="f-14 underline cursor-pointer text-highlight"
                      @click="openModalPreview(item)"
                    >
                      example Json
                    </div>
                  </template>
                </div>
              </template>
            </InputText>
          </b-col>
          <b-col cols="1" :key="'trash-' + item.id">
            <font-awesome-icon
              icon="trash-alt"
              class="mt-3 color-primary cursor-pointer"
              @click="deleteItem(index)"
            ></font-awesome-icon>
          </b-col>
        </template>
        <b-col>
          <div class="d-flex justify-content-center">
            <b-button class="main-color btn-save" @click="addItem"
              >Add</b-button
            >
          </div></b-col
        >
      </b-row>
      <FooterAction @submit="updateWebHookList(0)" routePath="/setting" />
    </b-container>
    <ModalPreviewBody :tabIndex="0" ref="modal"></ModalPreviewBody>
  </div>
</template>
<script>
import InputText from "@/components/inputs/InputText";
import ModalPreviewBody from "../modalPreviewBody.vue";

export default {
  components: { InputText, ModalPreviewBody },
  data() {
    return {
      data: [],
      options: [
        {
          value: 1,
          text: "Create",
        },
        {
          value: 2,
          text: "Update",
        },
        {
          value: 3,
          text: "Resolve",
        },
        {
          value: 4,
          text: "Close",
        },
      ],
    };
  },
  methods: {
    async getData() {
      const response = await this.axios(`/setting/GetTicketWebhook`);
      this.data = response.data.detail;
    },
    async updateWebHookList() {
      this.$bus.$emit("showLoading");
      const response = await this.axios.put(`/setting/UpdateTicketWebhook`, {
        webhook_list: this.data,
      });
      this.getData();
      this.$bus.$emit("hideLoading");
      if (response.data.result == 1) this.successAlert();
      else this.errorAlert(response.data.message);
    },
    openModalPreview(example_json) {
      this.$refs.modal.openModal(example_json);
    },

    deleteItem(index) {
      this.data.splice(index, 1);
    },
    addItem() {
      this.data.push({
        id: 0,
        name: "",
        url: "",
        ticket_webhook_action_id: 1,
        ticket_webhook_action_name: "",
      });
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style scoped>
.text-highlight {
  color: var(--primary-color);
  font-weight: bold;
  opacity: 1 !important;
  text-decoration: underline;
}
</style>
