<template>
  <b-modal v-model="showModal" ref="modalSelectProduct" centered @hidden="hide">
    <template #modal-header>Assign To</template>

    <HeaderPanel
      title="ADMIN LIST"
      :filter="filter"
      placeholder="Search ID, Admin Name"
      :hasDropdown="false"
      :hasFilter="false"
      @search="handleSearch"
    />
    <b-table
      striped
      hover
      responsive
      :items="items"
      :fields="fields"
      :busy="isBusy"
      show-empty
      empty-text="No matching records found"
      class="mt-2"
    >
      <template v-slot:cell(staff_id)="data">
        {{ data.item.staff_id || "-" }}
      </template>
      <template v-slot:table-busy>
        <div class="text-center text-black my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong class="ml-2">Loading...</strong>
        </div>
      </template>
      <template v-slot:cell(action)="data">
        <b-button
          @click.prevent="submit(data.item)"
          :class="[
            (isList && selectedAdmin.includes(data.item.user_guid)) ||
            (!isList && data.item.user_guid == selectedAdmin)
              ? 'btn-custom-primary'
              : 'btn-filter',
          ]"
        >
          <template v-if="isList">
            {{
              selectedAdmin.includes(data.item.user_guid)
                ? "Selected"
                : "Select"
            }}
          </template>
          <template v-else>
            {{ data.item.user_guid == selectedAdmin ? "Selected" : "Select" }}
          </template>
        </b-button>
      </template>
    </b-table>
    <Pagination
      :pageOptions="pageOptions"
      :filter="filter"
      :rows="rows"
      @pagination="pagination"
      @handleChangeTake="handleChangeTake"
    />

    <template #modal-footer>
      <b-row>
        <b-col class="text-left">
          <b-button @click.prevent="hide(1)" class="border-btn"
            >Cancel</b-button
          >
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-button class="submit-btn" @click.prevent="submit">Save</b-button>
        </b-col>
      </b-row>
    </template>
  </b-modal>
</template>

<script>
import Pagination from "@/components/Pagination";
export default {
  components: {
    Pagination,
  },
  props: {
    selectedAdmin: {
      required: false,
    },
    isList: {
      required: false,
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      showModal: false,
      fields: [
        {
          key: "action",
          label: "Action",
        },
        {
          key: "staff_id",
          label: "Staff ID.",
        },
        {
          key: "name",
          label: "Admin Name",
        },
        {
          key: "email",
          label: "Email",
        },
      ],
      items: [],
      isBusy: false,
      checkAll: false,
      selectAllCb: false,
      rows: 0,
      showing: 1,
      showingTo: 10,
      checkboxInactive: false,
      checkboxActive: false,
      filter: {
        search: "",
        page: 1,
        take: 5,
      },
      pageOptions: [
        { value: 5, text: "5 / page" },
        { value: 10, text: "10 / page" },
        { value: 20, text: "20 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
    };
  },

  watch: {
    showModal: {
      handler(value) {
        if (value) {
          this.filter = {
            search: "",
            user_guid: "",
            page: 1,
            take: 5,
          };
          this.getList();
        }
      },
    },
  },
  methods: {
    async show() {
      this.showModal = true;
    },
    hide() {
      this.showModal = false;
    },
    async filterByGuid(id) {
      this.filter = {
        search: "",
        page: 1,
        take: 5,
        user_guid: id,
      };
      await this.getList();
      return this.items[0];
    },
    getList: async function () {
      this.isBusy = true;
      const payload = {
        ...this.filter,
        pageSize: this.filter.take,
      };
      const { data } = await this.axios.post(`/ticket/filter/admin`, payload);
      if (data.result == 1) {
        this.items = data.detail.data;
        this.rows = data.detail.total;
      }
      this.isBusy = false;
    },
    handleSearch(e) {
      this.filter = {
        ...this.filter,
        ...e,
        page: 1,
      };
      this.getList();
    },
    pagination(value) {
      this.filter.page = value;
      this.getList();
    },
    handleChangeTake(take) {
      this.filter.page = 1;
      this.filter.take = take;
      this.getList();
    },

    submit(item) {
      this.$emit("select", item);
      this.hide();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .modal-header {
  background-color: var(--theme-secondary-color);
  color: var(--font-color);
  font-weight: 600;
}
::v-deep .modal-dialog {
  max-width: 800px;
}
::v-deep .input-group-text {
  padding: 0;
  background-color: transparent;
}
::v-deep thead {
  background-color: var(--theme-secondary-color);
  color: var(--font-color);
  text-align: center;
}
::v-deep .modal-footer {
  justify-content: space-between;
}
.btn-save {
  color: #fff;
  background-color: var(--primary-color);
  width: 100%;
  border: none;
  padding: 5px 50px;
  border-radius: 0px;
}
.btn-cancel {
  color: #fff;
  background-color: #39424e;
  width: 100%;
  border: none;
  padding: 5px 50px;
  border-radius: 0px;
}
</style>
