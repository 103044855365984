<template>
  <b-modal
    centered
    v-model="isShow"
    title="Ticket Template"
    size="lg"
    hide-footer
  >
    <div class="">
      <b-row class="no-gutters mt-3">
        <b-col md="12" class="justify-content-start">
          <UploadFileV2
            textFloat="File"
            placeholder="Please Choose File"
            format=""
            name="file"
            text="*Please upload only file .xlsx less than 10 MB"
            isRequired
            v-on:onFileChange="uploadFile"
            v-on:delete="deleteFile"
            :fileName="filename"
            v-model="attachment"
            id="uploadfile"
            :v="$v.filename"
            :isValidate="$v.filename.$error"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-button size="md" @click="hide" class="btn-cancel">Cancel</b-button>
        </b-col>
        <b-col class="text-right">
          <b-button
            id="upload"
            class="main-color btn-save color-btn"
            size="md"
            @click="submit"
            >Upload</b-button
          >
        </b-col>
      </b-row>
    </div>
  </b-modal>
</template>

<script>
import { required } from "vuelidate/lib/validators";
export default {
  validations() {
    return {
      filename: {
        required,
      },
    };
  },
  data() {
    return { isShow: false, filename: "", attachment: "" };
  },
  methods: {
    hide() {
      this.isShow = false;
    },
    show() {
      this.$v.filename.$reset();
      this.isShow = true;
      this.filename = null;
      this.attachment = null;
    },
    async submit() {
      this.$v.filename.$touch();
      if (this.$v.filename.$error) return;
      let payload = {
        Base64String: this.attachment,
        is_video: false,
        is_file: true,
        type: 1,
        file_name: this.filename,
      };
      const result = await this.axios.post("/news/UploadFile", payload);
      this.$emit("uploadFile", {
        name: this.filename,
        path: result.data.detail,
      });
    },
    uploadFile(file) {
      this.filename = file.name;
      this.isDisable = false;

      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.attachment = reader.result;
      };
    },
    deleteFile() {
      this.attachment = null;
      this.filename = null;
      this.isDisable = true;
    },
  },
};
</script>

<style></style>
