<template>
  <div>
    <div class="table-header my-3">Product Bundle AH</div>
    <b-table
      responsive
      striped
      hover
      :items="items"
      :fields="field"
      :busy="isBusy"
      show-empty
      empty-text="No matching records found"
      @sort-changed="$emit('sort-changed')"
      :sort-by.sync="filters.ahsortBy"
      no-local-sorting
      :sort-desc.sync="filters.ahsortDesc"
    >
      <template v-slot:cell(product_name_1)="data">
        <div class="d-flex">
          <div class="col-4">
            <b-img
              class="product-image"
              :src="data.item.img_1 || default_image"
              @error="handleImageSrc"
            />
            <b-img
              class="product-image ml-2"
              :src="data.item.img_2 || default_image"
              @error="handleImageSrc"
            />
          </div>
          <div class="text-left col-8">
            {{ data.item.product_name_1 }}
            <br />{{ data.item.product_name_2 }}
          </div>
        </div>
      </template>

      <template v-slot:cell(support)="data">
        <span> {{ data.item.support | numeral("0,0.0000") }}% </span>
      </template>
      <template v-slot:cell(confidence)="data">
        <span> {{ data.item.confidence | numeral("0,0.0000") }}% </span>
      </template>
      <template v-slot:cell(bills)="data">
        <span>
          {{ data.item.bills | numeral("0,0") }}({{ data.item.percen_bills }}%)
        </span>
      </template>
      <template v-slot:cell(total_customer)="data">
        <span>
          {{ data.item.total_customer | numeral("0,0") }}
        </span>
      </template>
    </b-table>
    <Pagination
      @handleChangeTake="(val) => $emit('perPageChange', val)"
      @pagination="(val) => $emit('pageChange', val)"
      :filter="filters"
      page="ahpage"
      take="ahtake"
      :rows="rows"
      :limit="2"
    />
  </div>
</template>

<script>
export default {
  props: {
    filters: {
      required: true,
    },
    rows: {
      required: true,
      default: 0,
      type: Number,
    },
    items: { required: true },
    isBusy: {
      required: false,
    },
  },
  data() {
    return {
      field: [
        { key: "ah_no_1", label: "AH No 1" },
        { key: "ah_1", label: "AH Name" },
        { key: "ah_no_2", label: "AH No 2" },
        { key: "ah_2", label: "AH Name" },
        { key: "support", label: "Support" },
        { key: "confidence", label: "Confidence" },
        { key: "support", label: "Support" },
        { key: "lift", label: "Lift" },
        { key: "total_count", label: "Total Count" },
        { key: "total_customer", label: "Total Customer" },
      ],
      //   ah_1: "Hourglass",
      //   ah_no_1: "AH1381010101",
      //   ah_2: "Hourglass",
      //   ah_no_2: "AH1381010101",
      //   support: 0.1821908449,
      //   confidence: 1.6,
      //   lift: 14,
      //   total_count: 0,
      //   total_customer: 8,
      // },
    };
  },
};
</script>

<style></style>
